@import '@styles/common/mixins';
@import '@styles/common/variables';

.upgrade-modal {
    .ant-input-number,
    .ant-input-number-group-wrapper {
        width: 100%;
    }
}

.plan-detail,
.modules-modal {
    .button-action {
        width: 100%;
        display: flex;
        justify-content: center;
        border-radius: rem(8);
        font-weight: 600;
        font-size: rem(16) !important;
        line-height: rem(24) !important;
    }
}

.plan-detail,
.default-dashboard {
    .title-text {
        @include text-style(700, rem(24), rem(32));
    }

    .ant-tag {
        @include text-style(600, rem(14), rem(20), var(--primary));
        border-color: var(--primary);
        background-color: $white;
    }

    .ant-btn-default {
        color: var(--primary);
        border: none;
        background-color: $orange-5;

        &:hover,
        &:focus {
            color: var(--primary);
            border: none;
        }
    }

    .ant-progress .ant-progress-success-bg,
    .ant-progress .ant-progress-bg {
        background-color: var(--primary);
    }
    .ant-tabs {
        height: 100%;
        .ant-tabs-content {
            height: 100%;
            .ant-tabs-tabpane {
                height: 100%;
                min-height: rem(224);
            }
        }
    }

    .ant-tabs-nav {
        margin-bottom: rem(20);
        .ant-tabs-tab {
            margin: 0;
            padding: 0;
        }
        .ant-tabs-tab-btn {
            @include text-style(500, rem(14), rem(20), $neutral-text-bg);
            padding: rem(8) rem(12);
            border-radius: rem(8);
        }

        .ant-tabs-tab-active > .ant-tabs-tab-btn {
            color: var(--primary);
            background-color: $orange-5;
        }
        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
                .ant-tabs-ink-bar-animated {
                    width: 0 !important;
                }
            }
        }
        &::before {
            border: none;
        }
    }

    .plan-tab {
        min-height: rem(224);
        position: relative;
        height: 100%;
    }

    .btn-add-more {
        font-weight: 600;
        font-style: italic;
        color: var(--primary);
        margin-left: rem(16);
    }
}

.default-dashboard {
    .report {
        height: rem(154);
        width: 50%;

        @include max-lg {
            width: 100%;
        }
    }
}
.upgrade-license {
    .ant-tag {
        @include text-style(600, rem(14), rem(20), var(--primary));
        border-color: var(--primary);
        background-color: $white;
    }

    .plan-item {
        padding: 8px;
        border: 1px solid $gray-200;
    }

    .selected-service {
        padding: 8px;
        border: 1px solid $primary;
    }

    .plan-item:hover,
    .selected-service:hover {
        transform: scale(1.02);
        transition: 0.5s ease-in-out;
    }

    .plan-item:last-child,
    .selected-service:last-child {
        padding-right: 0;
    }

    .icon-plan {
        margin-bottom: 20px;

        .action-icon {
            width: 40px;
            height: 40px;
            margin: 0 auto;
        }
    }

    .name-plan {
        min-height: 60px;
        margin-bottom: 8px;
        @include ellipse-multi-lines(1);
        @include text-style(600, 20px, 30px, $ant-active-text);
    }

    .price-plan {
        margin-bottom: 8px;
        @include ellipse-multi-lines(1);
        @include text-style(600, 20px, 28px, $gray-500);
    }

    .disable {
        color: $neutral-40;
        cursor: not-allowed;
    }
}

.modules-banner {
    // Using multi applications
    // .slick-slide {
    //     margin-right: 2rem;
    // }

    // .item {
    //     text-align: center;
    //     border-radius: 8px;
    //     width: 700px !important;
    //     height: 154px;
    //     max-width: 700px;
    //     max-height: 154px;
    //     background-color: #fff4ea;
    // }

    .item {
        text-align: center;
        border-radius: 8px;
        height: 154px;
        max-height: 154px;
        background-color: $orange-5;
    }

    .button-action {
        font-weight: 600;
        justify-content: center;
        padding: rem(8) rem(20);
        font-size: rem(16) !important;
        line-height: rem(24) !important;
    }
}

.modules-modal {
    .listed {
        list-style: inside;
        @include text-style(500, rem(16), rem(24), $primary);
    }

    .transaction-form {
        border: 1px solid $neutral-text;
        border-radius: rem(8);
        padding: rem(10) rem(12);
    }
}

.section-form {
    .text-center {
        text-align: center;
    }

    .sub-title {
        font-weight: 500;
    }

    :global {
        .ant-btn {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.price-calculator-form {
    .discount-subtitle {
        @include text-style(400, rem(10), rem(16), $help-gray);
        font-style: italic;
    }
}
