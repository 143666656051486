@import './_resets';
@import './types';
@import './variables';
@import './media';
@import './mixins';
@import '@zodinet-pos/core-ui/dist/styles.css';

:root {
    --primary: #fb7331;
    --secondary: #f4f4ff;
}

#root {
    height: 100%;
}

// Typography

.body-2 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6a707e;
}

.animated {
    background-repeat: no-repeat;
    background-position: left top;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.animatedFadeInUp {
    opacity: 0;
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

.table-wrapper {
    // margin-left: -0.5rem;
    // margin-right: -0.5rem;
}

// .table-thead-text {
//     font-size: 12px;
//     color: #9a9a9a;
//     font-weight: 500;
//     display: flex;
//     align-items: center;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     text-transform: none;
//     letter-spacing: normal;
// }

.table-tbody-text {
    font-size: 12px;
    color: #414141;
    font-weight: 500;
    margin-bottom: 0px;
    padding: 10px 0px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-action {
    height: auto;
    line-height: 0.5;
    padding: 14px;
}

.btn {
    font-weight: 600;
    text-align: center;
    color: #525f7f;

    &:hover {
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
    }
}

h3 {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 0px;
}

h2 {
    font-weight: 600;
    font-size: 1.25rem;
}

.btn-add-new {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    border: 1px solid transparent;
    padding: 0.625rem 1.25rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    line-height: 1.5;
    height: auto;
}

.detail-container {
    background-color: #fff;
    padding: 2rem 2.5rem;
    border-radius: 12px;
}

.main-tab {
    & > .ant-tabs-nav {
        .ant-tabs-tab {
            padding: 10px 14px;
            min-width: 120px;
            justify-content: center;
            background: #ededed;
            color: #414141;

            + .ant-tabs-tab {
                margin: 0 0 0 8px;
            }

            &.ant-tabs-tab-active {
                background: #fff1d5;
            }
        }

        .ant-tabs-ink-bar {
            display: none;
        }
    }
}

.ant-layout,
.ant-layout-footer,
.ant-layout-header {
    background-color: $white;
    border-bottom: 1.5px solid #eaecf0;
}

.ant-layout-content {
    border-bottom: 1px solid #eaecf0;
    max-height: calc(100vh - 75px);
    overflow-y: auto;
}

// .ant-menu-submenu-title {
//     color: $gray-text;
// }

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: 0px;
    border-inline-end: none !important;
}

.ant-form-item-label > label {
    font-weight: bold;
}

.ant-page-header {
    border-radius: $ant-border-radius-base;
}

// .ant-menu-submenu-popup > .ant-menu {
//     background-color: #2a132e;
// }

// .ant-menu-tree-arrow {
//     position: absolute;
//     top: 50%;
//     right: 16px;
//     width: 10px;
//     color: #fff;
//     transform: translateY(-50%);
//     transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
// }

// .ant-menu-tree-arrow::before,
// .ant-menu-tree-arrow::after {
//     position: absolute;
//     width: 6px;
//     height: 1.5px;
//     background-color: currentcolor;
//     border-radius: 2px;
//     transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
//         transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
//         top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
//         color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//     content: '';
// }

// .ant-menu-tree-arrow::before {
//     transform: rotate(45deg) translateY(-2.5px);
// }

// .ant-menu-tree-arrow::after {
//     transform: rotate(-45deg) translateY(2.5px);
// }

.ant-table-thead > tr > th {
    background-color: #fff;

    &::before {
        display: none;
    }
}

.ant-menu-submenu-title:hover {
    background-color: $ant-active-bg;
}

ul.ant-menu-sub .ant-menu-title-content {
    padding-left: 5px;
}

.ant-menu-light .ant-menu-item:hover {
    background-color: $ant-active-bg;
}

.ant-btn {
    display: inline-flex;
    align-items: center;
    height: 44px;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 24px;

    &.ant-btn-sm {
        height: 24px;
        padding: 0px 7px;
        font-size: 14px;
    }
}

// Antd button variant solid hover
.ant-btn-variant-solid {
    &:not(:disabled):hover {
        background-color: var(--primary) !important;
        filter: brightness(95%);
    }
}

.ant-btn-variant-outlined {
    &:not(:disabled):hover {
        border-color: var(--primary) !important;
        color: var(--primary) !important;
    }
}

.ant-btn-primary {
    background: var(--primary);
    border: 0;

    &:hover {
        background: var(--primary);
        border: 0;
    }
    &:focus {
        border-color: var(--primary);
        background: var(--primary);
    }
}

.search-item {
    width: 200px;
    @include max-sm {
        width: 100%;
    }
}

@include max-size(577px) {
    .btn-submit-note {
        width: 100% !important;
    }
}

.filter-group {
    padding: 5px;
    border: 1px solid #f2f4f7;
    border-radius: 8px;
    background: #f9fafb;
    height: 62px;

    .ant-radio-button-wrapper {
        border: none;
        background-color: transparent;
        padding: 10px 14px;
        height: auto;
        &::before {
            background-color: transparent;
        }
        &:hover::before {
            background-color: transparent;
        }
        &.ant-radio-button-wrapper-checked {
            border-radius: 6px;
            background: #ffffff;
            box-shadow: 0px 1px 3px rgb(16 24 40 / 10%), 0px 1px 2px rgb(16 24 40 / 6%);
            border-radius: 6px;
            border: none;
            color: $gray-700;
            :not(.ant-radio-button-wrapper-disabled)::before {
                background-color: transparent;
            }
        }
    }
}
.drawer-form-container {
    .ant-drawer-body {
        padding-top: 12px;
    }
    .ant-card {
        box-shadow: none;
        .ant-card-body {
            padding: 0;
            box-shadow: none;
        }
    }

    .ant-page-header,
    .ant-page-header.has-breadcrumb {
        padding: 0;
    }
}

/** Card */
.card-shadow {
    &.ant-card {
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
    }
}

.ant-switch.ant-switch-checked {
    background-color: var(--primary) !important;
}

.ant-switch {
    background-color: #bfbfbf;
}

.label-field-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    content: '*';
}

.ant-result-404 {
    .ant-result-extra {
        .ant-space-item {
            .ant-space-horizontal {
                .ant-space-item:last-child:has(span[role='img']) {
                    display: none;
                }
            }
        }
    }
}

.ant-upload.ant-upload-select {
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    background: transparent !important;
}

.ant-btn-primary:not(:disabled):hover {
    background-color: var(--primary);
}

.ant-layout .ant-layout-sider-zero-width-trigger {
    top: 20px;
    width: 24px;
    height: 24px;
    background: transparent;
}

.ant-layout-sider-zero-width-trigger {
    top: 15px;
    left: 8px;
    position: absolute !important;
    cursor: pointer;

    @include max-size(990px) {
        width: 35px;

        .anticon {
            display: none;
        }
        svg {
            width: 42px;
            height: 42px;
        }
        background: transparent !important;
    }
}

.ant-layout-sider-zero-width > * {
    overflow: inherit;
}
.ant-space {
    @include max-size(577px) {
        flex-direction: column;
    }
}

.ant-float-btn.ant-float-btn-primary {
    .ant-float-btn-body {
        background-color: var(--primary);
    }
}

.custom-date-range {
    .ant-picker-dropdown .ant-picker-panel-container {
        overflow-x: scroll;
    }
    // resize ant-picker responsive
    .ant-picker-dropdown .ant-picker-range-wrapper {
        @include max-size(777px) {
            width: 550px;
            overflow-x: scroll;
        }
        @include max-size(577px) {
            width: 380px;
            overflow-x: scroll;
        }
        @include max-size(400px) {
            width: 300px;
            overflow-x: scroll;
        }
    }
}

.ant-table-body {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

.ant-radio-group {
    &.ant-radio-group-solid {
        .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
            &:hover {
                color: $primary;
            }
        }

        .ant-radio-button-wrapper-checked {
            background: $primary;
            border-color: $primary;
            &:hover {
                background: $primary;
                border-color: $primary;
            }

            &:focus-within {
                box-shadow: none;
            }
        }
    }
}
