@import '@styles/common/variables';
@import '@styles/common/mixins';

.planPage {
    background-image: url('../../../public/images/plan-background.png') !important;
    :global {
        .ant-spin-nested-loading {
            overflow-y: auto;
        }
    }
    .planContainer {
        max-width: $screen-xl;
        background-color: $white;
        border: 1px solid $gray-200;
        box-shadow: 0px 2px 80px 0px rgba(0, 0, 0, 0.06);

        @include max-size(1440px) {
            height: 100%;
        }

        .headerContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 24px 32px;
            text-transform: uppercase;
            border-bottom: 1px solid $gray-200;
            @include text-style(700, 24px, 20px, $primary-text);

            .logoContainer {
                padding-bottom: 16px;

                .logoImg {
                    height: 45px;
                }
            }
        }

        .formContainer {
            padding: 32px;
            padding-bottom: 28px;
            @include max-lg {
                padding: 24px;
            }

            .planItem {
                padding: 8px;
                border: 1px solid $gray-200;
            }

            .selectedService {
                padding: 8px;
                border: 1px solid $primary;
            }

            .planItem:hover,
            .selectedService:hover {
                transform: scale(1.02);
                transition: 0.5s ease-in-out;
            }

            .planItem:last-child,
            .selectedService:last-child {
                padding-right: 0;
            }

            .iconPlan {
                margin-bottom: 20px;

                .actionIcon {
                    width: 40px;
                    height: 40px;
                    margin: 0 auto;
                }
            }

            .namePlan {
                min-height: 60px;
                margin-bottom: 8px;
                @include ellipse-multi-lines();
                @include text-style(600, 20px, 30px, $ant-active-text);
            }

            .pricePlan {
                margin-bottom: 8px;
                @include ellipse-multi-lines(1);
                @include text-style(600, 24px, 32px, $gray-500);
            }

            :global {
                .ant-input,
                .ant-input-password,
                .ant-input-number {
                    width: 100%;
                    height: 48px;
                    @include text-style(rem(14), 400, rem(24), $primary-text);
                }

                .ant-input-number-input,
                .ant-input-number-group-wrapper {
                    width: 100%;
                    height: 46px;
                }

                .ant-input-password {
                    .ant-input {
                        height: revert;
                    }
                }
            }
            .totalPrice {
                margin-top: rem(8);
                font-style: italic;
                color: $negative-4;
            }
        }

        .btnContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 24px 16px 0;
            .btn {
                width: rem(260);

                @include max-md {
                    width: 100%;
                    margin-right: 0;
                    margin-left: rem(24);
                }
                align-items: center;
                display: flex;
                justify-content: center;

                span {
                    @include text-style(500, 18px, 24px, $white);
                }
            }
        }

        .formWrapper {
            :global {
                @include max-size(577px) {
                    .ant-row {
                        width: 100%;
                        margin-right: 0px !important;
                        margin-left: 0px !important;

                        .ant-col {
                            padding-left: 0px !important;
                            padding-right: 0px !important;
                        }
                    }
                }

                .ant-select-selector {
                    display: flex;
                    align-items: center;
                    height: 48px;
                }
                .ant-select-selection-search-input {
                    height: 100%;
                }
            }
        }

        :global {
            .ant-input-number-input-wrap {
                height: 100%;
                display: flex;
                align-items: center;
            }
        }
    }
}
